import ApplicationController from "controllers/application_controller";

interface ToastOptions {
  dismissable?: boolean;
}

export default class extends ApplicationController {
  static targets = ["successTemplate", "warningTemplate", "errorTemplate"];
  static values = {
    successMessage: String,
    errorMessage: String,
  };

  declare successTemplateTarget: HTMLElement;
  declare warningTemplateTarget: HTMLElement;
  declare errorTemplateTarget: HTMLElement;
  declare successMessageValue: string;
  declare errorMessageValue: string;

  readonly defaultError = `
    Something went wrong with your request. Please contact support if you believe this is an error.
  `;

  connect(): void {
    if (this.successMessageValue.length) {
      this.renderSuccess(this.successMessageValue);
    }
    if (this.errorMessageValue.length) {
      this.renderError(this.errorMessageValue);
    }

    // If we had previously set a flash message on the data attribute, we want this to go away
    // before the next page load, even though the toast container is a permanent turbolinks fixture
    this.element.dataset.toastsSuccessMessageValue = "";
    this.element.dataset.toastsErrorMessageValue = "";
  }

  disconnect(): void {
    this.element.remove();
  }

  renderSuccess(
    message: string,
    duration = 3000,
    options: ToastOptions = {},
  ): void {
    this.render(message, duration, this.successTemplateTarget, options);
  }

  renderError(
    message = this.defaultError,
    duration = 8000,
    options: ToastOptions = {},
  ): void {
    this.render(message, duration, this.errorTemplateTarget, options);
  }

  renderWarning(
    message: string,
    duration = 8000,
    options: ToastOptions = {},
  ): void {
    this.render(message, duration, this.warningTemplateTarget, options);
  }

  render(
    message: string,
    duration: number,
    template: HTMLElement,
    options: ToastOptions = {},
  ): void {
    const html = template.innerHTML
      .replace("{{ message }}", message)
      .replace("{{ duration }}", duration.toString());

    const parser = new DOMParser();
    const document = parser.parseFromString(html, "text/html");
    const toast = document.body.firstChild as HTMLElement;

    if (options.dismissable === false) {
      toast.querySelector(".toast-dismiss").remove();
    }

    this.element.appendChild(toast);
  }
}
