import ApplicationController from "controllers/application_controller";

export default class extends ApplicationController {
  static targets = ["ssoTab", "databaseTab", "passwordLink"];

  declare ssoTabTarget: HTMLElement;
  declare databaseTabTarget: HTMLElement;
  declare passwordLinkTarget: HTMLElement;

  switchTab(): void {
    this.ssoTabTarget.classList.toggle("hidden");
    this.databaseTabTarget.classList.toggle("hidden");
    this.passwordLinkTarget.classList.toggle("hidden");
  }
}
