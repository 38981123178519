import ApplicationController from "controllers/application_controller";

export default class extends ApplicationController {
  static values = { duration: Number };

  declare durationValue: number;
  declare isHovered: boolean;

  connect(): void {
    this.isHovered = false;

    // This will let us avoid removing the toast while we're hovering over it, e.g. to click a link
    this.element.addEventListener("mouseenter", () => (this.isHovered = true));
    this.element.addEventListener("mouseleave", () => (this.isHovered = false));

    // We want to schedule this class to be added on the next tick of the event loop so transitions
    // will be applied. If we immediately add the class, it will immediately reach its end state.
    setTimeout(() => {
      this.element.classList.add("toast--visible");
    }, 100);

    // Schedule the notification to be removed.
    this.queueRemoval();
  }

  queueRemoval(): void {
    if (this.durationValue !== 0) {
      setTimeout(() => {
        this.removeUnlessHovered();
      }, this.durationValue);
    }
  }

  removeUnlessHovered(): void {
    if (this.isHovered) {
      setTimeout(() => {
        this.removeUnlessHovered();
      }, 500);
    } else {
      this.remove();
    }
  }

  remove(): void {
    // Remove the visible class so the toast can be animated out
    this.element.classList.remove("toast--visible");

    // Then remove the toast from the DOM after it's animated
    setTimeout(() => {
      this.element.remove();
    }, 500);
  }
}
