import ApplicationController from "controllers/application_controller";

export default class extends ApplicationController {
  static targets = [
    "password",
    "confirmation",
    "checkLower",
    "checkUpper",
    "checkNumber",
    "checkSpecial",
    "checkLength",
    "checkMatch",
    "submit",
  ];

  static values = {
    enabled: Boolean,
  };

  declare readonly passwordTarget: HTMLInputElement;
  declare readonly confirmationTarget: HTMLInputElement;
  declare readonly checkLowerTarget: HTMLElement;
  declare readonly checkUpperTarget: HTMLElement;
  declare readonly checkNumberTarget: HTMLElement;
  declare readonly checkSpecialTarget: HTMLElement;
  declare readonly checkLengthTarget: HTMLElement;
  declare readonly checkMatchTarget: HTMLElement;
  declare readonly submitTarget: HTMLButtonElement;
  declare readonly enabledValue: boolean;

  connect(): void {
    this.doSubmitCheck();
  }

  doChecks(): void {
    if (!this.enabledValue) return;

    this.doLowerCheck();
    this.doUpperCheck();
    this.doNumberCheck();
    this.doSpecialCheck();
    this.doLengthCheck();
    this.doMatchCheck();
    this.doSubmitCheck();
  }

  doLowerCheck(): void {
    this.checkLowerTarget.classList.toggle(
      "success",
      this.meetsLowerRequirement,
    );
  }

  doUpperCheck(): void {
    this.checkUpperTarget.classList.toggle(
      "success",
      this.meetsUpperRequirement,
    );
  }

  doNumberCheck(): void {
    this.checkNumberTarget.classList.toggle(
      "success",
      this.meetsNumberRequirement,
    );
  }

  doSpecialCheck(): void {
    this.checkSpecialTarget.classList.toggle(
      "success",
      this.meetsSpecialRequirement,
    );
  }

  doLengthCheck(): void {
    this.checkLengthTarget.classList.toggle(
      "success",
      this.meetsLengthRequirement,
    );
  }

  doMatchCheck(): void {
    this.checkMatchTarget.classList.toggle(
      "success",
      this.meetsMatchRequirement,
    );
  }

  doSubmitCheck(): void {
    if (!this.enabledValue) return;

    this.submitTarget.disabled = !this.meetsAllRequirements;
  }

  get meetsLowerRequirement(): boolean {
    return !!this.passwordTarget.value.match(/[a-z]/);
  }

  get meetsUpperRequirement(): boolean {
    return !!this.passwordTarget.value.match(/[A-Z]/);
  }

  get meetsNumberRequirement(): boolean {
    return !!this.passwordTarget.value.match(/[0-9]/);
  }

  get meetsSpecialRequirement(): boolean {
    return !!this.passwordTarget.value.match(/[^a-z0-9]/i);
  }

  get meetsLengthRequirement(): boolean {
    const minLength = parseInt(this.element.dataset.minLength, 10);

    return this.passwordTarget.value.length >= minLength;
  }

  get meetsMatchRequirement(): boolean {
    const password = this.passwordTarget.value;
    const confirmation = this.confirmationTarget.value;

    return password.length && confirmation.length && password === confirmation;
  }

  get meetsAllRequirements(): boolean {
    return (
      this.meetsLowerRequirement &&
      this.meetsUpperRequirement &&
      this.meetsNumberRequirement &&
      this.meetsSpecialRequirement &&
      this.meetsLengthRequirement &&
      this.meetsMatchRequirement
    );
  }
}
