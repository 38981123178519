import ApplicationController from "controllers/application_controller";
import Rails from "@rails/ujs";

interface ResendResponse {
  notification: string;
}

export default class extends ApplicationController {
  static targets = ["submit"];
  static values = { resendUrl: String };

  declare submitTarget: HTMLButtonElement;
  declare resendUrlValue: string;

  checkForSubmit(event: CustomEvent): void {
    const input = event.target as HTMLInputElement;

    if (input.value.length === 6) {
      Rails.disableElement(this.submitTarget);
      input.form?.submit();
    }
  }

  resendCode(event: CustomEvent): void {
    event.preventDefault();

    Rails.ajax({
      type: "POST",
      url: this.resendUrlValue,
      success: this.showResendSuccess,
    });
  }

  showResendSuccess = (response: ResendResponse): void => {
    this.renderSuccessToast(response.notification);
  };
}
