import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();

// Application controllers
const applicationContext = require.context(
  "controllers",
  true,
  /(toast|toasts)_controller\.ts$/,
);
const applicationDefinitions = definitionsFromContext(applicationContext);

// Auth controllers
const authContext = require.context(
  "controllers/auth",
  true,
  /_controller\.ts$/,
);
const authDefinitions = definitionsFromContext(authContext);

application.load([...applicationDefinitions, ...authDefinitions]);
