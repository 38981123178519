import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import SidebarController from "./sidebar_controller";
import SidebarCountersController from "./sidebar_counters_controller";
import ToastsController from "./toasts_controller";
import TooltipController from "./tooltip_controller";
import DateInputController from "./date_input_controller";
import FieldGroupsListController from "./field_groups_list_controller";
import FieldListController from "./fields_list_controller";
import NotesController from "./notes_controller";
import DocumentsController from "./documents_controller";
import OmnisearchController from "./omnisearch_controller";

export type SuccessResponse = {
  html: string;
  notification: string;
};

export default class extends Controller {
  get sidebarCountersController(): SidebarCountersController {
    return this.getController("sidebar-counters");
  }

  get sidebarController(): SidebarController {
    return this.getController("sidebar");
  }

  get toastsController(): ToastsController {
    return this.getController("toasts");
  }

  get tooltipsController(): TooltipController {
    return this.getController("tooltip");
  }

  get dateInputsController(): DateInputController {
    return this.getController("date-input");
  }

  get fieldGroupsListController(): FieldGroupsListController {
    return this.getController("field-groups-list");
  }

  get fieldsListController(): FieldListController {
    return this.getController("fields-list");
  }

  get notesController(): NotesController {
    return this.getController("notes");
  }

  get documentsController(): DocumentsController {
    return this.getController("documents");
  }

  get omnisearchController(): OmnisearchController {
    return this.getController("omnisearch");
  }

  get element(): HTMLElement {
    return this.scope.element as HTMLElement;
  }

  toggleSidebar(event?: CustomEvent): void {
    this.sidebarController.toggle(event);
  }

  openSidebar(event: CustomEvent): void {
    const target = event.currentTarget as HTMLElement;
    const wide = !!target.dataset.wide;

    this.sidebarController.open({ wide });
  }

  requestSidebarContent(event: CustomEvent): void {
    const target = event.currentTarget as HTMLElement;
    const loaderText = target.dataset.loaderText;

    this.sidebarController.showSidebarLoader(loaderText);

    Rails.ajax({
      type: "GET",
      url: target.dataset.url,
      success: this.loadRemoteSidebarHtml.bind(this),
      error: this.showSidebarError.bind(this),
    });
  }

  replaceSidebarContentSuccess(event: CustomEvent): void {
    const response = this.extractAjaxResponse(event) as SuccessResponse;

    this.loadRemoteSidebarFromString(response.html);
    this.renderSuccessToast(response.notification);
  }

  loadRemoteSidebarContent(event: CustomEvent): void {
    this.loadRemoteSidebarHtml(this.extractAjaxResponse(event));
  }

  showOmnisearch(): void {
    this.omnisearchController.open();
  }

  requestSelectValue(event: CustomEvent): void {
    const target = event.currentTarget as HTMLSelectElement;
    const containerId = target.dataset.containerId;
    const container = document.getElementById(containerId);

    if (!target.value) return;

    Rails.ajax({
      type: "GET",
      url: target.value,
      success: this.populateRemoteHTML.bind(this, container),
    });
  }

  populateRemoteHTML(container: HTMLElement, responseDocument: Document): void {
    container.innerHTML = responseDocument.body.innerHTML;
  }

  loadRemoteSidebarFromString(string: string): void {
    this.sidebarController.loadContentFromString(string);

    // The element that triggers a sidebar opening has a listener for the AJAX success event that
    // will eventually call this method. If we run `hidetooltips` any sooner, the element that
    // handler is attached to will go away, and the content will never be populated.
    this.hideTooltips();
  }

  loadRemoteSidebarHtml(htmlDocument: Document): void {
    this.sidebarController.loadContent(htmlDocument);
    // The element that triggers a sidebar opening has a listener for the AJAX success event that
    // will eventually call this method. If we run `hidetooltips` any sooner, the element that
    // handler is attached to will go away, and the content will never be populated.
    this.hideTooltips();
  }

  // Fire a Rails.ajax request to the given URL and load it as a sidebar
  requestRemoteHtml(url: string): void {
    Rails.ajax({
      type: "GET",
      url: url,
      success: this.loadRemoteSidebarHtml.bind(this),
    });
  }

  hideTooltips(): void {
    this.tooltipsController && this.tooltipsController.hideAll();
  }

  closeCalendars(): void {
    this.dateInputsController && this.dateInputsController.closeAll();
  }

  showSidebarError(event: CustomEvent): void {
    this.sidebarController.showError(event);
  }

  renderAjaxSuccessToast(event: CustomEvent): void {
    this.renderSuccessToast(this.extractAjaxResponse(event));
  }

  renderSuccessToast(message: string, duration = 5000): void {
    this.toastsController.renderSuccess(message, duration);
  }

  renderErrorToast(message?: string, duration = 10000): void {
    this.toastsController.renderError(message, duration);
  }

  preventDefault(event: CustomEvent): void {
    event.preventDefault();
  }

  getController<T extends Controller>(
    controller: string,
    scope?: HTMLElement,
  ): T {
    const element = (scope || document).querySelector(
      `[data-controller~="${controller}"]`,
    );

    return this.application.getControllerForElementAndIdentifier(
      element,
      controller,
    ) as T;
  }

  extractAjaxResponse<T>(event: CustomEvent): T {
    return event.detail[0] as T;
  }
}
